:root {
  --brand-regular: #5fc4b8;
  --brand-regular-hover: #b8fff7;
  --brand-regular-active: #b8fff74d;
  --brand-regular-disabled: #5fc4b84d;
  --brand-light: #c7f6f0;
  --brand-medium: #33a19b;
  --brand-dark: #0a2a2d;
  --brand-dark-hover: #0f3e43;
  --brand-dark-active: #0f3e434d;
  --brand-dark-disabled: #0a2a2d4d;
  --brand-light-gray: #35383e;

  --text-primary: #ffffff;
  --text-primary-off: #d6e7e7;
  --text-secondary: #5b6067;
  --text-tertiary: #c9d4de;
  --text-dark: #161618;
  --text-dark-off: #98a4ae;
  --sub-text-primary: #c1cfd9;
  --sub-text-secondary: #ccd4d8;

  --canvas-dark: #000000;
  --canvas-dark-off: #2c2e32;
  --canvas-primary: #161618;
  --canvas-secondary: #1d1d1f;
  --canvas-tertiary: #101011;

  --black-primary: #121212;
  --black-secondary: #1d1d1f;
  --black-tertiary: #0d0d0f;

  --gray-primary: #25272b;
  --gray-secondary: #33373e;
  --gray-tertiary: #262629;

  --faint-gray: #ffffff26;
  --input-primary: #0000004d;
  --shadow-primary: #0000007b;

  --border-primary: #ffffff4d;
  --border-secondary: #5b6067;
  --border-tertiary: #606060;

  --warning-bg: #342215;
  --warning-color: #ffbb55;
  --error-bg: #2e141c;
  --error-color: #ff8064;
  --info-bg: #191631;
  --info-color: #969bff;

  --link-font-size: 0.81rem;
  --input-font-size: 0.875rem;
  --content-font-size: 1.125rem;
  --header-font-size: 1.5rem;
  --content-font-weight: 300;
  --link-font-weight: 400;
  --bold-font-weight: bold;
  --font-increase-rem: 0.375rem;
}

body {
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout-wrapper {
  height: calc(100vh - 114px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-primary {
  color: var(--text-primary);
  background-color: var(--brand-regular);
  border-color: var(--brand-regular);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: var(--text-primary-off);
  background-color: var(--brand-regular-disabled);
  border-color: var(--brand-regular-disabled);
}

.btn-primary:hover {
  color: var(--text-primary);
  background-color: var(--brand-regular-hover);
  border-color: var(--brand-regular-hover);
}

.layout-wrapper button,
.modal button {
  border-radius: 0;
}

/* Common styling for dropdown menu */
.dropdown .dropdown-menu {
  background-color: var(--gray-secondary);
}

.dropdown .dropdown-item {
  color: var(--text-primary);
}

.dropdown .dropdown-item:focus,
.dropdown .dropdown-item:hover {
  background-color: var(--gray-primary);
  outline: 0;
}

.dropdown .dropdown-item:focus,
.dropdown .dropdown-item:hover {
  background-color: var(--gray-primary);
  outline: 0;
}

/* Common styling for modal */
.modal .modal-header .close {
  color: var(--text-primary);
}

.modal .modal-content {
  background: var(--gray-secondary);
  color: var(--text-primary);
}

.dropdown .dropdown-toggle {
  min-width: 160px;
  text-align: left;
}

/* Common styling for table */
.table-wrapper #formGroupPagination {
  margin: 0 4px;
}

.table-wrapper .controlRow__root {
  margin: 15px 0;
}

.Toastify__toast-container {
  width: 378px !important;
  --toastify-font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.toastContainer {
  background: var(--gray-primary) !important;
  box-shadow: 0px 4px 20px var(--shadow-primary) !important;
  border-radius: 5px;
  padding: 24px !important;
  color: var(--text-primary) !important;
}

.toastContainer .Toastify__close-button {
  color: var(--text-primary);
  opacity: 1;
}

#feedback_icon {
  cursor: pointer;
  position: fixed;
  bottom: 0px;
  right: 12px;
  width: 42px;
  height: 42px;
  z-index: 9999;
  animation: load_up 1s forwards;
  -webkit-animation: load_up 1s forwards;
}

@keyframes load_up {
  from {
    bottom: -100px;
  }
  to {
    bottom: 12px;
  }
}

.active__learning-blank {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #78bcb8;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  color: #fff;
  min-width: 24px;
  margin-bottom: 0;
  height: 20px;
  text-align: center;
}
